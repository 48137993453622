.container {
    height: 100vh;
    height: calc(100vh - 50px); /* 50px is the header's height */
    width: 100%;

    font-size: 0.875rem;
    line-height: 1.25rem;
}

.panel {
    height: calc((100vh - 50px - 1px) / 3); /* 50px is the header's height; 1px border; */
    border-bottom: 1 solid transparent;
}

.panelOptions {
    grid-area: panelOptions;
    border-bottom: 1.5px solid #ccc;
}

.panelInput {
    grid-area: panelInput;
}

.panelOutput {
    grid-area: panelOutput;
}

@supports (display: grid) {
    @media (min-width: 520px) {
        .container {
            display: grid;
            grid-template-areas: 
                "panelOptions panelOutput"
                "panelInput   panelOutput";
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 270px 1fr;
        }

        .panel {
            height: unset;
        }
    }
}