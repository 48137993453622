.container {
    background-color: #20232a;
    color: #fff;
    /* min-height: 50px; */
    padding: 8px 0;
    width: 100%;
    transform: translateZ(0);
}

.container header {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    text-align: left;
    margin-left: 1.20%;
    user-select: none;
}

.container header img {
    border-style: none;
    box-sizing: content-box;
    max-width: 100%;
    height: 100%;
    margin-right: 10px;
}

.container header h1 {
    display: block;
    font-size: 1.25rem;
    margin: 0;
    position: relative;
}

.container header p {
    display: block;
    font-size: 1rem;
    margin: 0;
    margin-left: 1rem;
    position: relative;
    /* Vertically align with the title */
    padding-top: 0.2em;
}

.container a {
    -webkit-box-align: center;
    align-items: center;
    border: 0;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    height: 34px;
    text-decoration: none;
}
